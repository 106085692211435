import React, { useEffect, useState } from "react";
import "./header.css";
import { Link, NavLink } from "react-router-dom";
import Navbarmenu from "./Navbarmenue";
import EnrollFooter from "../EnrollFooter/EnrollFooter";
export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowHeader(true);
      } else if (window.scrollY === 0) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`showHeader  ${showHeader ? "" : "hideHeader"}`}>
        <header className={`headerMain  ${isSticky ? "stickyHeader" : ""}`}>
          {/* <header className='headerMain  stickyHeader'> */}
          <div className='headerContainer'>
            <div className='headerLeft'>
              <a
                className='cs-site_branding'
                href='https://encodingcareers.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src='/images/EC_logo.svg' alt='Logo' />
              </a>
            </div>
            <div className='headerCenter'>
              <Navbarmenu />
            </div>
          </div>
        </header>
      </div>
      {/* Footer start */}
      {/* <div
        className='enrollFooter'
        style={{
          transform: showFooter ? "translateY(0)" : "translateY(112%)",
          transition: "transform 0.4s ease",
        }}
      > */}
      <div className={`enrollFooter  ${!showHeader ? "" : "hideFooter"}`}>
        <EnrollFooter />
      </div>
      {/* Footer end */}
    </>
  );
}
