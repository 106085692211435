import { useState } from "react";
import { Icon } from "@iconify/react";
import { useSwipeable } from "react-swipeable";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";

export default function Quote1({ handleClose, name }) {
  const largeProps: ConfettiProps = {
    force: 0.8,
    duration: 5000,
    particleCount: 300,
    width: 1600,
    colors: [
      "#a1d6d8",
      "#2d8184",
      "#e6f0f0",
      "#4bc9ce",
      "#999696",
      "#a8ff78",
      "#78ffd6",
      "rgba(163, 102, 159)",
    ],
    zIndex: 1400,
  };
  const [clicked, setClicked] = useState(false);
  const handlers = useSwipeable({
    onSwipedUp: () => setClicked(true),
    onSwipedDown: () => setClicked(true),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className='quote-main'>
      <div className='quote-top'>
        <h3>Impressive Progress&nbsp;{name}!</h3>
      </div>
      <Icon
        icon='solar:close-square-line-duotone'
        className='quoteClose'
        onClick={() => handleClose(3)}
      />
      <div className='quote-middle'>
        {" "}
        <div className='quote-img'>
          <h5>
            You've completed 75% of the assessment, and your dream career is
            within reach.
          </h5>

          <div className='tray-container'>
            <div
              className='tray-background'
              style={{
                // background: "linear-Gradient(to top, #008aff, #00ffe7)",
                // background:
                //   "linear-gradient(90deg, rgba(45,129,132,1) 30%, rgba(255,255,255,1) 90%)",
                // animation: "animate 5s linear infinite",

                backgroundImage:
                  "linear-gradient(90deg, rgba(45, 129, 132, 1) 10%, rgba(255, 255, 255, 1) 70%)",
                backgroundSize: "200% 200%",
                animation: "animateGradient 1s infinite",
                width: "75%",
                // height: "50px",
              }}
            >
              {" "}
            </div>
            &nbsp;
          </div>
          <div {...handlers}>
            <img
              src={
                clicked
                  ? "/images/quote_03_bubble_02.png"
                  : "/images/quote_03_bubble_01.png"
              }
              alt='Avatar'
              style={{
                height: clicked ? "180px" : "150px",
                width: "180px",
              }}
              className='img_07'
            />
          </div>
          {clicked ? (
            <ConfettiExplosion
              {...largeProps}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <img
              src='/images/quote_03_knife.png'
              alt='Avatar'
              className='img_08'
              style={{
                height: "130px",
                width: "100px",
                cursor: "pointer",
              }}
              onClick={() => {
                setClicked(true);
              }}
            />
          )}

          <img
            src='/images/jobs.png'
            alt='Avatar'
            className='img_09'
            style={{
              height: "155px",
              width: "100px",
            }}
          />
          <img
            src={clicked ? "/images/bonus_02.png" : "/images/bonus_01.png"}
            alt='Avatar'
            className='img_10'
            style={{
              height: "120px",
              width: "180px",
            }}
          />
        </div>
      </div>
      {clicked && (
        <div
          className='quote-button2'
          onClick={() => handleClose(3)}
          // style={{ marginTop: "-00px !important" }}
        >
          <h5>Continue to Next Section </h5>
          <Icon icon='carbon:arrow-right' />{" "}
        </div>
      )}
    </div>
  );
}
