// import ContactInfoWidget from "../Widget/ContactInfoWidget";
// import MenuWidget from "../Widget/MenuWidget";
// import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
// import TextWidget from "../Widget/TextWidget";
import "./footer.css";
const copyrightLinks = [
  {
    title: "Terms of Use",
    href: "/",
  },
  {
    title: "Privacy Policy",
    href: "/",
  },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className='cs-fooer_main'>
      {/* <TextWidget
        logoSrc='/images/EC_logo.svg'
        logoAlt='Logo'
        text='Encoding Careers is a platform to build your Career Success Through Passion.'
      /> */}
      <div className='cs-text_widget'>
        <div className='footerLeft'>
          <img src='/images/EC_logo.svg' alt='logo' />
          <p className='footerP'>
            Encoding Careers is a platform to build your Career Success Through
            Passion.
          </p>
        </div>
      </div>
      <div className='copyText'>
        <SocialWidget />
        {/* <MenuWidget menuItems={copyrightLinks} variant=' cs-style2' /> */}
        <div className='cs-copyright'>Copyright © 2024 encodingcareers.com</div>
      </div>
    </footer>
  );
}
