import "./intro.css";
import { Icon } from "@iconify/react";
import Countdown from "react-countdown";

export default function IntroModule({ setOpenIntro }) {
  // Calculate the time until the next midnight
  const now = new Date();
  const nextMidnight = new Date();
  nextMidnight.setHours(24, 0, 0, 0); // Set to midnight

  const timeUntilMidnight = nextMidnight - now;
  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <span>
        {hours}h: {minutes}m: {seconds}s
      </span>
    );
  };
  return (
    <div className='introModule'>
      <div className='introTitle'>
        {/* <Icon icon='game-icons:spell-book' className='introIcon' /> */}

        <h6 className='blinkText'>Passion - Profile Assesment</h6>
      </div>

      <div className='ccArrowDiv'>
        <Icon icon='emojione-v1:flag-for-india' className='indiaIcon' />
        <h5 className='ccH5'>India's First Algo Assessment</h5>
      </div>

      <div className='introCard'>
        <div className='dTitle'>
          <h5 className='dText'>Building </h5>
          <span className='dSpan'> Sucessful Career</span>
          <br />
          <h5 className='dText'>Made Easy with</h5>{" "}
          <span className='dSpan'>Encoding Careers</span>
        </div>
        <div className='dLeftDetails'>
          <h4 className='text2'>5 Career Path Choices:</h4>
          <div className='introRoadmapChips'>
            <div className='introRow1'>
              <div className='introRoadmapChip'>
                <Icon
                  icon='fluent:wallet-credit-card-48-regular'
                  className='chipIcon'
                  style={{ fontSize: "22px" }}
                />
                <h6 className='text2'>Credit Management</h6>
              </div>

              <div className='introRoadmapChip'>
                <Icon
                  icon='vaadin:line-bar-chart'
                  className='chipIcon'
                  style={{ fontSize: "20px" }}
                />
                <h6 className='text2'>Equity Reasearch</h6>
              </div>
              <div className='introRoadmapChip'>
                <Icon
                  icon='healthicons:money-bag-outline'
                  className='chipIcon'
                  style={{ fontSize: "22px" }}
                />
                <h6 className='text2'>Wealth Management</h6>
              </div>
            </div>
            <div className='introRow2'>
              <div className='introRoadmapChip'>
                <Icon
                  icon='fluent-mdl2:recruitment-management'
                  className='chipIcon'
                />
                <h6 className='text2'> Management Consulting</h6>
              </div>

              <div className='introRoadmapChip'>
                <Icon icon='guidance:bank' className='chipIcon' />
                <h6 className='text2'>Investment Banking</h6>
              </div>
            </div>
          </div>

          <Icon icon='solar:map-arrow-up-line-duotone' className='dIcon2' />
          <h4 className='text3'>Respond to Interactive Finance Questions</h4>
          <Icon icon='solar:map-arrow-up-line-duotone' className='dIcon3' />
          <h4 className='text4'>Kickstart you Assessment</h4>
        </div>
      </div>
      <div
        className='button glass bmButton shine'
        style={{ margin: "0px 20px" }}
        onClick={() => setOpenIntro(false)}
      >
        <div className='introTimer'>
          <h3>
            Start Now for <s style={{ color: "red" }}>₹199</s>
          </h3>
          <h3>
            Free for next:{" "}
            <Countdown
              date={Date.now() + timeUntilMidnight}
              renderer={renderer}
            />
          </h3>
        </div>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div>
    </div>
  );
}
