// import "./quiz.scss";
import { Icon } from "@iconify/react";
import { useState } from "react";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";

export default function Quote1({ handleClose, name }) {
  const largeProps: ConfettiProps = {
    force: 0.8,
    duration: 5000,
    particleCount: 100,
    width: 1600,
    colors: [
      "#a1d6d8",
      "#2d8184",
      "#e6f0f0",
      "#4bc9ce",
      "#999696",
      "#a8ff78",
      "#78ffd6",
      "rgba(163, 102, 159)",
    ],
    zIndex: 1400,
  };
  const [clicked, setClicked] = useState(false);
  return (
    <div className='quote-main'>
      <div className='quote-top'>
        <h3>Great Start&nbsp;{name}</h3>
      </div>
      <Icon
        icon='solar:close-square-line-duotone'
        className='quoteClose'
        onClick={() => handleClose(1)}
      />
      <div className='quote-middle'>
        <div className='quote-img'>
          <h5>You have completed 15% already!</h5>
          <div className='tray-container'>
            <div
              className='tray-background'
              style={{
                // background: "linear-Gradient(to top, #008aff, #00ffe7)",
                // background:
                //   "linear-gradient(90deg, rgba(45,129,132,1) 30%, rgba(255,255,255,1) 90%)",
                // animation: "animate 5s linear infinite",

                backgroundImage:
                  "linear-gradient(90deg, rgba(45, 129, 132, 1) 10%, rgba(255, 255, 255, 1) 70%)",
                backgroundSize: "200% 200%",
                animation: "animateGradient 1s infinite",
                width: "15%",
                // height: "50px",
              }}
            >
              {" "}
            </div>
            &nbsp;
          </div>

          {/* <img
            src={
              clicked
                ? "/images/quote_01_bubble_02.png"
                : "/images/quote_01_bubble_01.gif"
            }
            alt='Avatar'
            style={{
              height: "90px",
              width: "110px  ",
              cursor: "pointer",
            }}
            className='img_01'
            onClick={() => {
              setClicked(true);
              // console.log("clicked");
            }}
          /> */}
          {clicked ? (
            <div>
              <img
                src='/images/quote_01_bubble_02.png'
                alt='Avatar'
                style={{
                  height: "120px",
                  width: "140px  ",
                  cursor: "pointer",
                }}
                className='img_01'
                onClick={() => {
                  setClicked(true);
                  // console.log("clicked");
                }}
              />
            </div>
          ) : (
            <img
              src='/images/quote_01_bubble_01.gif'
              alt='Avatar'
              className='img_01_01'
              style={{
                // scale: "0.75",
                height: "490px",
                width: "410px  ",
                cursor: "pointer",
              }}
              onClick={() => {
                setClicked(true);
                // console.log("clicked");
              }}
            />
          )}

          <img
            src='/images/benjamin1.png'
            alt='Avatar'
            className='img_02'
            style={{
              height: "110px",
              width: "100px",
            }}
          />
          <img
            src={
              clicked
                ? "/images/quote_01_fact_02.png"
                : "/images/quote_01_fact_01.png"
            }
            alt='Avatar'
            className='img_03'
            style={{
              height: "180px",
              width: "180px",
            }}
          />
        </div>
      </div>
      {clicked && (
        <div
          className='quote-button'
          onClick={() => handleClose(1)}
          // style={{ width: "150px !important" }}
        >
          <h5 style={{}}>Continue to Next Section </h5>
          <Icon icon='carbon:arrow-right' />{" "}
          <ConfettiExplosion
            {...largeProps}
            style={{ position: "absolute", right: "50%" }}
          />
        </div>
      )}
    </div>
  );
}
