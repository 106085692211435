// import "./quiz.scss";
import { useState } from "react";
import { Icon } from "@iconify/react";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";

export default function Quote1({ handleClose, name }) {
  const [clicked, setClicked] = useState(false);
  const largeProps: ConfettiProps = {
    force: 0.8,
    duration: 5000,
    particleCount: 200,
    width: 1600,
    colors: [
      "#a1d6d8",
      "#2d8184",
      "#e6f0f0",
      "#4bc9ce",
      "#999696",
      "#a8ff78",
      "#78ffd6",
      "rgba(163, 102, 159)",
    ],
    zIndex: 1400,
  };
  return (
    <div className='quote-main'>
      <div className='quote-top'>
        <h3>Halfway there&nbsp;{name}!</h3>
      </div>
      <Icon
        icon='solar:close-square-line-duotone'
        className='quoteClose'
        onClick={() => handleClose(2)}
      />
      <div className='quote-middle'>
        <div className='quote-img'>
          <h5>
            You've completed 50% assessment with your insightful responses
          </h5>
          <div className='tray-container'>
            <div
              className='tray-background'
              style={{
                // background: "linear-Gradient(to top, #008aff, #00ffe7)",
                // background:
                //   "linear-gradient(90deg, rgba(45,129,132,1) 30%, rgba(255,255,255,1) 90%)",
                // animation: "animate 5s linear infinite",

                backgroundImage:
                  "linear-gradient(90deg, rgba(45, 129, 132, 1) 10%, rgba(255, 255, 255, 1) 70%)",
                backgroundSize: "200% 200%",
                animation: "animateGradient 1s infinite",
                width: "50%",
                // height: "50px",
              }}
            >
              {" "}
            </div>
            &nbsp;
          </div>
          <img
            src={
              clicked
                ? "/images/quote_02_bubble_02.png"
                : "/images/quote_02_bubble_01.png"
            }
            alt='Avatar'
            style={{
              height: "180px",
              width: "250px",
            }}
            className='img_04'
          />

          <img
            src='/images/buffet.png'
            alt='Avatar'
            className='img_05'
            style={{
              height: "110px",
              width: "120px",
            }}
          />

          {clicked ? (
            <div>
              {" "}
              <ConfettiExplosion
                {...largeProps}
                style={{ position: "absolute", right: "50%" }}
              />
              <img
                src='/images/quote_02_fact_02.png'
                alt='Avatar'
                className='img_06'
                style={{
                  height: "200px",
                  width: "200px",
                }}
              />
            </div>
          ) : (
            <img
              src='/images/quote_02_fact_01.gif'
              alt='Avatar'
              className='img_06_01'
              style={{
                height: "120px",
                width: "120px",
                cursor: "pointer",
              }}
              onClick={() => {
                setClicked(true);
                // console.log("clicked");
              }}
            />
          )}
        </div>
      </div>
      {clicked && (
        <div
          className='quote-button2'
          onClick={() => handleClose(2)}
          // style={{ marginTop: "-00px !important" }}
        >
          <h5>Continue to Next Section </h5>
          <Icon icon='carbon:arrow-right' />{" "}
        </div>
      )}
    </div>
  );
}
