import "./enrollmodal.css";
import { Icon } from "@iconify/react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const EnrollModal = ({ handleClose }) => {
  const [roadMap, setRoadMap] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const handleChange = (event) => {
    setRoadMap(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    console.log(roadMap, name, email, mobile);
    await axios
      .post(
        "https://quiz.encodingcareers.com/submitUser",
        // "/submitUser",
        {
          roadMap: roadMap,
          name: name,
          email: email,
          mobile: mobile,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        setShowResult(true);
      });
  };

  return (
    <div className='EmMain'>
      <Icon
        icon='solar:close-square-line-duotone'
        className='quoteClose'
        onClick={() => handleClose()}
      />
      <div className='EmMainLogo'>
        <img src='/images/EC_logo.svg' alt='Logo' />
      </div>
      {showResult ? (
        <div className='enrollResult'>
          <h5>
            Thank you for your interest in{" "}
            {roadMap === "IB"
              ? "Investment Banking"
              : roadMap === "MC"
              ? "Management Consulting"
              : roadMap === "ER"
              ? "Equity Research"
              : roadMap === "WM"
              ? "Wealth Management"
              : "Credit Management"}{" "}
            Roadmap. Unfortunately it is currently sold out. We will send you an
            email as soon as new batches open up.
          </h5>
        </div>
      ) : (
        <div className='enrollContent'>
          <form onSubmit={handleSubmit}>
            <h5>Select the right roadmap for you:</h5>
            <div className='enrollSelect'>
              <FormControl fullWidth>
                <InputLabel
                  id='demo-simple-select-label'
                  style={{ color: "white", margin: "auto" }}
                >
                  Road Map{" "}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  required
                  value={roadMap}
                  label='roadMap'
                  onChange={handleChange}
                  height='25px'
                  sx={{
                    color: "white",
                    borderColor: "white !important",

                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white !important",
                    },
                    ".MuiSvgIcon-root": {
                      color: "#fff",
                    },
                  }}
                >
                  <MenuItem value={"IB"}>Investment Banking</MenuItem>
                  <MenuItem value={"MC"}>Management Consulting</MenuItem>
                  <MenuItem value={"ER"}>Equity Research</MenuItem>
                  <MenuItem value={"WM"}>Wealth Management</MenuItem>
                  <MenuItem value={"CM"}>Credit Management</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className='enrollPath'>
              <h6>Confused about your career path?</h6>
              <h6>Take our Passion Profile Assessment here</h6>
            </div> */}
            <div className='enrollInputs'>
              <h5>Full Name:</h5>

              <input
                type='text'
                className='enrollInput'
                placeholder='Your name'
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <h5>Email:</h5>

              <input
                type='email'
                className='enrollInput'
                placeholder='example@example.com'
                value={email}
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <h5>Mobile Number:</h5>

              <input
                type='text'
                className='enrollInput'
                placeholder='XXXXXXXXXX'
                required
                minLength={10}
                maxLength={10}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />

              <button
                className='enrollSubmit'
                type='submit'
                disabled={disabled}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EnrollModal;
