import { Icon } from "@iconify/react";
import IntroModule from "./IntroModule";
import { section_1, section_2, section_3, section_4 } from "./questions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState, useEffect } from "react";
import QuestionCard from "./QuestionsCard";
import "./quiz.css";
import { motion } from "framer-motion";
import Modal from "@mui/material/Modal";
import Quote1 from "./Quote1";
import Quote2 from "./Quote2";
import Quote3 from "./Quote3";
import axios from "axios";
import QuizResult from "./QuizResults";

const style = {
  position: "absolute",
  top: "0px",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "100%",
  // height: "800px",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  color: "black",
  // boxShadow: 24,
  // p: 4,
};

export default function Quiz() {
  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState({});
  const [open1, setOpen1] = useState({ status: false, opened: false });
  const [open2, setOpen2] = useState({ status: false, opened: false });
  const [open3, setOpen3] = useState({ status: false, opened: false });
  const [openIntro, setOpenIntro] = useState(true);

  const [errorMsg, setErrorMgs] = useState("test");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const closeIntro = () => {
    setOpenIntro(false);
  };
  const handleClose = async (id) => {
    if (id === 1) {
      setOpen1({ status: false, opened: true });
    }
    if (id === 2) {
      setOpen2({ status: false, opened: true });
    }
    if (id === 3) {
      setOpen3({ status: false, opened: true });
    }

    // await new Promise((r) => setTimeout(r, 500));
    setSelectedQuestion((prevValue) => prevValue + 1);
    setSelectedSection((prevValue) => prevValue + 1);
  };
  const handleClick = async (clickType) => {
    if (clickType === "name") {
      if (name.trim() !== "") {
        try {
          const response = await axios.post("/quizStart", {
            user_name: name,
          });
          if (response.data !== "error") {
            setId(response.data);
            setSelectedSection(1);
            setSelectedQuestion(1);
          }
        } catch (error) {
          console.error("Error sending data:", error);
        }
      } else {
        setErrorMgs("Please Enter your name before moving ahead");
        setShowErrorMsg(true);
      }
    }
    if (clickType === "email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      if (isValidEmail) {
        try {
          const response = await axios.post("/quizEnd", {
            user_email: email,
            id: id,
            user_name: name,
          });
          if (response.data !== "error") {
            setScore(response.data);
            console.log(response.data);
            setSelectedSection(6);
          }
        } catch (error) {
          console.error("Error sending data:", error);
        }
      } else {
        setErrorMgs("Please enter a valid email address");
        setShowErrorMsg(true);
      }
    }
  };
  useEffect(() => {
    if (selectedQuestion === 4 && open1.opened === false) {
      setOpen1({ status: true, opened: false });
      setSelectedQuestion((prevValue) => prevValue - 1);
      setSelectedSection((prevValue) => prevValue - 1);
    } else if (selectedQuestion === 9 && open2.opened === false) {
      setOpen2({ status: true, opened: false });
      setSelectedQuestion((prevValue) => prevValue - 1);
      setSelectedSection((prevValue) => prevValue - 1);
    } else if (selectedQuestion === 15 && open3.opened === false) {
      setOpen3({ status: true, opened: false });
      setSelectedQuestion((prevValue) => prevValue - 1);
      setSelectedSection((prevValue) => prevValue - 1);
    }
  }, [selectedQuestion]);

  return (
    <div className='wrapper'>
      <div className='quiz-container'>
        <div className='tray-container'>
          <div
            className='tray-background'
            style={{
              // background: "linear-Gradient(to top, #008aff, #00ffe7)",
              // background:
              //   "linear-gradient(90deg, rgba(45,129,132,1) 30%, rgba(255,255,255,1) 90%)",
              // animation: "animate 5s linear infinite",

              backgroundImage:
                "linear-gradient(90deg, rgba(45, 129, 132, 1) 10%, rgba(255, 255, 255, 1) 60%)",
              backgroundSize: "200% 200%",
              animation: "animateGradient 3s infinite",
              width:
                selectedSection === 1
                  ? "25%"
                  : selectedSection === 2
                  ? "50%"
                  : selectedSection === 3
                  ? "75%"
                  : selectedSection >= 4
                  ? "100%"
                  : "0px",
            }}
          >
            {" "}
            &nbsp;
          </div>
          <div
            className='quiz-tray'
            style={{
              // background: "linear-Gradient(to top, #008aff, #00ffe7)",
              // background: "linear-Gradient(to left, #2d8184, #ffffff)",
              // animation: "animate 5s linear infinite",
              position: "relative !important",
              zIndex: 2,
            }}
          >
            <div
              className={
                "icon " + (selectedSection === 1 && "quiz-icon-selected")
              }
            >
              <Icon icon='maki:college' className='quiz-icon' />{" "}
            </div>

            <div
              className={
                "icon " + (selectedSection === 2 && "quiz-icon-selected")
              }
            >
              <Icon icon='game-icons:brain' className='quiz-icon' />
            </div>
            <div
              className={
                "icon " + (selectedSection === 3 && "quiz-icon-selected")
              }
            >
              <Icon icon='octicon:goal-16' className='quiz-icon' />
            </div>
            <div
              className={
                "icon " + (selectedSection === 4 && "quiz-icon-selected")
              }
            >
              <Icon
                icon='game-icons:boxing-glove-surprise'
                className='quiz-icon'
              />{" "}
            </div>
          </div>
        </div>
        {selectedSection === 0 && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 0 ? " selected" : "")
              }
              style={{ marginBottom: "250px" }}
            >
              <div className='quiz-begin'>
                <h6 style={{ margin: "15px 0px" }}>
                  Welcome! before we start, can we get you name:
                </h6>

                <input
                  type='text'
                  className='quiz-input'
                  placeholder='Please enter your name'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrorMgs(false);
                  }}
                />

                <div
                  className='quiz-button-begin'
                  onClick={() => {
                    handleClick("name");
                  }}
                >
                  <h5>Start</h5>
                </div>
                {showErrorMsg && (
                  <h6 style={{ marginBottom: "15px" }}>{errorMsg}</h6>
                )}
              </div>
            </div>
          </motion.div>
        )}
        {selectedSection === 5 && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            style={{ marginBottom: "250px" }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 5 ? " selected" : "")
              }
            >
              <div className='quiz-begin'>
                <h6 className='bold' style={{ padding: "15px" }}>
                  All Done {name}, Please Enter you email so we can send you
                  your score along with your early bird discount code for the
                  Roadmap{" "}
                </h6>

                <input
                  type='text'
                  className='quiz-input'
                  placeholder='Please enter your email'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorMgs(false);
                  }}
                />

                <div
                  className='quiz-button-begin'
                  onClick={() => {
                    handleClick("email");
                  }}
                >
                  <h5>Submit</h5>
                </div>
                {showErrorMsg && <h6>{errorMsg}</h6>}
              </div>
            </div>
          </motion.div>
        )}
        {selectedSection === 6 && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className={"quiz-results-main"}>
              <QuizResult score={score} name={name} />
            </div>
          </motion.div>
        )}
        {selectedSection === 1 ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 1 ? " selected" : "")
              }
            >
              <div className='quiz-head'>
                <div className='icon'>
                  {/* <SchoolOutlinedIcon
                    className={selectedSection === 1 ? " selected" : ""}
                  /> */}
                  <Icon icon='maki:college' className='quiz-icon' />
                </div>
                <h6 className='bold'>ACADEMICS & CERTIFICATIONS</h6>
                <div className='icon'>
                  {selectedSection === 1 ? (
                    <CircularProgressbar
                      value={((selectedQuestion - 1) * 100) / 3}
                      text={`${parseInt(((selectedQuestion - 1) * 100) / 3)}%`}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // pathColor: `rgba(62, 152, 199, ${90 / 100})`,
                        pathColor: "#4bc9ce",
                        textColor: "#fff",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#161616",
                      })}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <QuestionCard
                section={section_1}
                selectedQuestion={selectedQuestion}
                selectedSection={selectedSection}
                setSelectedQuestion={setSelectedQuestion}
                setSelectedSection={setSelectedSection}
                answers={answers}
                setAnswers={setAnswers}
                open1={open1}
                setOpen1={setOpen1}
                id={id}
                name={name}
              />
            </div>
          </motion.div>
        ) : (
          ""
        )}
        {selectedSection === 2 ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 2 ? " selected" : "")
              }
            >
              <div className='quiz-head'>
                <div className='icon'>
                  <Icon icon='game-icons:brain' />
                  {/* <InterestsOutlinedIcon
                    className={selectedSection === 2 ? " selected" : ""}
                  /> */}
                </div>
                <h6 className='bold'>INTEREST & SKILLS</h6>
                <div className='icon'>
                  {selectedSection === 2 ? (
                    <CircularProgressbar
                      value={((selectedQuestion - 4) * 100) / 5}
                      text={`${parseInt(((selectedQuestion - 4) * 100) / 5)}%`}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // pathColor: `rgba(62, 152, 199, ${90 / 100})`,
                        pathColor: "#4bc9ce",
                        textColor: "#fff",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#161616",
                      })}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <QuestionCard
                section={section_2}
                selectedQuestion={selectedQuestion}
                selectedSection={selectedSection}
                setSelectedQuestion={setSelectedQuestion}
                setSelectedSection={setSelectedSection}
                answers={answers}
                setAnswers={setAnswers}
                id={id}
                name={name}
              />
            </div>
          </motion.div>
        ) : (
          ""
        )}
        {selectedSection === 3 ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 3 ? " selected" : "")
              }
            >
              <div className='quiz-head'>
                <div className='icon'>
                  <Icon icon='octicon:goal-16' className='quiz-icon' />
                </div>

                <h6 className='bold'>PERSONALITY & CAREER GOALS</h6>

                <div className='icon'>
                  {selectedSection === 3 ? (
                    <CircularProgressbar
                      value={((selectedQuestion - 9) * 100) / 6}
                      text={`${parseInt(((selectedQuestion - 9) * 100) / 6)}%`}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // pathColor: `rgba(62, 152, 199, ${90 / 100})`,
                        pathColor: "#4bc9ce",
                        textColor: "#fff",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#161616",
                      })}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <QuestionCard
                section={section_3}
                selectedQuestion={selectedQuestion}
                selectedSection={selectedSection}
                setSelectedQuestion={setSelectedQuestion}
                setSelectedSection={setSelectedSection}
                answers={answers}
                setAnswers={setAnswers}
                id={id}
                name={name}
              />
            </div>
          </motion.div>
        ) : (
          ""
        )}
        {selectedSection === 4 ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={
                "quiz-card " + (selectedSection === 4 ? " selected" : "")
              }
            >
              {" "}
              <div className='quiz-head'>
                <div className='icon'>
                  <Icon
                    icon='game-icons:boxing-glove-surprise'
                    className='quiz-icon'
                  />
                </div>

                <h6 className='bold'>MOTIVATION & WORK</h6>
                <div className='icon'>
                  {selectedSection === 4 ? (
                    <CircularProgressbar
                      value={((selectedQuestion - 15) * 100) / 6}
                      text={`${parseInt(((selectedQuestion - 15) * 100) / 6)}%`}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        pathTransitionDuration: 0.5,
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        // pathColor: `rgba(62, 152, 199, ${90 / 100})`,
                        pathColor: "#4bc9ce",
                        textColor: "#fff",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#161616",
                      })}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <QuestionCard
                section={section_4}
                selectedQuestion={selectedQuestion}
                selectedSection={selectedSection}
                setSelectedQuestion={setSelectedQuestion}
                setSelectedSection={setSelectedSection}
                answers={answers}
                setAnswers={setAnswers}
                id={id}
                name={name}
              />
            </div>
          </motion.div>
        ) : (
          ""
        )}
      </div>
      <Modal
        open={open1.status}
        onClose={handleClose}
        style={{ backdropFilter: "blur(8px)" }}
      >
        <div className='quoteModule'>
          <Quote1 handleClose={handleClose} name={name} />
        </div>
      </Modal>
      <Modal
        open={open2.status}
        onClose={handleClose}
        style={{ backdropFilter: "blur(8px)" }}
      >
        <div className='quoteModule'>
          <Quote2 handleClose={handleClose} name={name} />
        </div>
      </Modal>
      <Modal
        open={open3.status}
        onClose={handleClose}
        style={{ backdropFilter: "blur(8px)" }}
      >
        <div className='quoteModule'>
          <Quote3 handleClose={handleClose} name={name} />
        </div>
      </Modal>
      <Modal
        open={openIntro}
        onClose={closeIntro}
        style={{ backdropFilter: "blur(2px)" }}
      >
        <div>
          <IntroModule setOpenIntro={setOpenIntro} />
        </div>
      </Modal>
    </div>
  );
}
