import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export default function SocialWidget() {
  return (
    <div className='cs-social_btns cs-style1'>
      <a
        href='https://linkedin.com/in/karan-damania-276ba4160'
        className='cs-center'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon icon='fa6-brands:linkedin-in' />
      </a>
      <a
        href='https://twitter.com/Karan_Damania_'
        className='cs-center'
        target='_blank'
        rel='noopener noreferrer'
      >
        {" "}
        <Icon icon='fa6-brands:twitter' />
      </a>
      <a
        href='https://youtube.com/@decodingcareers.?si=1PKPZ8PWUg_2LrDM'
        className='cs-center'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon icon='fa6-brands:youtube' />
      </a>
      <a
        href='https://instagram.com/decoding_careers'
        className='cs-center'
        target='_blank'
        rel='noopener noreferrer'
      >
        {" "}
        <Icon icon='teenyicons:instagram-solid' />
      </a>
      <a
        href='https://facebook.com/DecodingCareers'
        className='cs-center'
        target='_blank'
        rel='noopener noreferrer'
      >
        {" "}
        <Icon icon='simple-icons:meta' />{" "}
      </a>
    </div>
  );
}
