export const section_1 = [
  {
    question_no: 1,
    section: 1,
    question: "What is your undergraduate major or degree focus?",
    answers_no: 1,
    options: {
      A: "Finance, Accounting, Economics",
      B: "Computer Science, Engineering",
      C: "Business Administration, Marketing",
    },
  },
  {
    question_no: 2,
    section: 1,
    question: "What advanced degrees are you interested in pursuing?",
    answers_no: 4,
    options: {
      A: "MBA",
      B: "CFA / FRM",
      C: "Data Science, Analytics",
      D: "CFP (Certified Financial Planner)",
    },
  },
  {
    question_no: 3,
    section: 1,
    question: "Are you planning or pursuing any course for skill development",
    answers_no: 4,
    options: {
      A: "Financial Modelling and Analysis",
      B: "Valuation methods & Techniques",
      C: "Data Analysis & Presentation",
      D: "Investment Planning & Investment Strategies",
    },
  },
];

export const section_2 = [
  {
    question_no: 4,
    section: 2,
    question:
      "How interested are you in analyzing financial statements and business models?",
    answers_no: 1,
    options: {
      A: "I love diving into financial statements and business models",
      B: "While I may not be crunching numbers all day, I do enjoy understanding financials and business model",
      C: "It's not my go-to activity, but hey, I'm open to giving it a shot!",
      D: "Not really my cup of tea. I prefer other aspects of finance!",
    },
  },
  {
    question_no: 5,
    section: 2,
    question:
      "Do you consider yourself detail-oriented, enjoy analyzing data, and have strong math skills?",
    answers_no: 1,
    options: {
      A: "I love digging into data, and have a thing for numbers",
      B: "I don't mind details, I might not be the person with a magnifying glass ( A balanced mix)",
      C: "I might not be the first in line when it comes to complex math problems",
    },
  },
  {
    question_no: 6,
    section: 2,
    question:
      "How interested are you in building strategies and coming up with solutions?",
    answers_no: 1,
    options: {
      A: "I take charge for building strategies and coming up with solutions",
      B: "I can be the part of team",
      C: "Not really my thing",
    },
  },
  {
    question_no: 7,
    section: 2,
    question:
      "How comfortable are you presenting your viewpoints and advising people?",
    answers_no: 1,
    options: {
      A: "I love sharing my viewpoints and advising people.",
      B: "I don't mind sharing advice and viewpoints when needed",
      C: "I prefer understanding other people's viewpoints",
    },
  },
  {
    question_no: 8,
    section: 2,
    question:
      "Do you enjoy networking and building relationships with new people?",
    answers_no: 1,
    options: {
      A: "Absolutely, I thrive on building connections and networking!",
      B: "I'm open to it; I see the value in networking and building relationships.",
      C: "Not my top priority, but I'm willing to step out of my comfort zone and give networking a try.",
      D: "Networking isn't really my thing",
    },
  },
];

export const section_3 = [
  {
    question_no: 9,
    section: 3,
    question: "Is travel for work acceptable to you?",
    answers_no: 1,
    options: {
      A: "Yes",
      B: "Occasionally",
      C: "No",
    },
  },
  {
    question_no: 10,
    section: 3,
    question: "Which best describes your personality?",
    answers_no: 4,
    options: {
      A: "Outgoing and persuasive",
      B: "Analytical and detail-oriented",
      C: "Driven and ambitious",
      D: "Patient and nurturing",
    },
  },
  {
    question_no: 11,
    section: 3,
    question: "How many hours per week are you willing to contribute at work ?",
    answers_no: 1,
    options: {
      A: "Upto 40 hours",
      B: "Upto 50 hours",
      C: "Upto 60 hours",
    },
  },
  {
    question_no: 12,
    section: 3,
    question: "Which role do you gravitate towards in teams?",
    answers_no: 3,
    options: {
      A: "Taking the lead",
      B: "Supporting others",
      C: "Contributing specialized expertise",
    },
  },
  {
    question_no: 13,
    section: 3,
    question: "Which personality resonates with you the most",
    answers_no: 2,
    options: {
      A: "Dealmakers who mastermind successful Merger & Acquisition",
      B: "Agents who drive organizational transformation and operational excellence",
      C: "Leaders who shape market perceptions and influence investment decisions with their research",
      D: "Trusted advisors who provide personalized financial guidance and wealth preservation strategies",
      E: "Specialists who navigate challenging financial situations to achieve favorable outcomes for creditors",
    },
  },
  {
    question_no: 14,
    section: 3,
    question: "Which work environment do you prefer?",
    answers_no: 4,
    options: {
      A: "Fast-paced, high pressure",
      B: "Autonomous and self-directed",
      C: "Collaborative team settings",
      D: "Structured with strong work-life balance",
    },
  },
];

export const section_4 = [
  {
    question_no: 15,
    section: 4,
    question: "What work energizes ? What motivates you most in a career?",
    answers_no: 3,
    options: {
      A: "Prestige and compensation",
      B: "Problem Solving and analysis",
      C: "Building relationships and helping people",
    },
  },
  {
    question_no: 16,
    section: 4,
    question: "Which business role model do you most admire?",
    answers_no: 2,
    options: {
      A: "Negotiators who excel in fundraising and capital allocation",
      B: "Problem-solving gurus who analyzing business challenges and devising strategic solutions.",
      C: "Market experts who accurately predict market trends and anticipate shifts in industry landscapes",
      D: "Investment portfolios optimizers who meet clients' financial goals and risk tolerance.",
      E: "Assessors who evaluate creditworthiness and manage lending risks effectively",
    },
  },
  {
    question_no: 17,
    section: 4,
    question: "During client interactions what tasks do you see prioritizing?",
    answers_no: 2,
    options: {
      A: "Providing in-depth financial analysis and recommendations",
      B: "Building rapport and understanding client goals and preferences",
      C: "Presenting innovative solutions and value-added services",
      D: "Negotiating terms and agreements to achieve mutual benefit",
    },
  },
  {
    question_no: 18,
    section: 4,
    question: "Which workplace culture do you prefer?",
    answers_no: 4,
    options: {
      A: "Innovative and bold",
      B: "Collaborative and supportive",
      C: "Detail-oriented and stable",
      D: "Driven and competitive",
    },
  },
  {
    question_no: 19,
    section: 4,
    question:
      "Which ethical principle do you think is most important in business?",
    answers_no: 3,
    options: {
      A: "Ensure fair processes",
      B: "Maximize profits",
      C: "Prioritize transparency",
    },
  },
  {
    question_no: 20,
    section: 4,
    question: "What is the best success strategy in your view?",
    answers_no: 4,
    options: {
      A: "Take calculated risks when needed",
      B: "Build strong relationships and networks",
      C: "Leverage specialized expertise",
      D: "Maintain integrity and transparency",
    },
  },
];

// export section_1,section_2, section_3, section_4;
