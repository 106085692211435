// import "./quiz.css";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";

const variants = {
  hiddenRight: { x: 200, opacity: 0 },
  visible: { x: 0, opacity: 1 },
  hiddenLeft: { x: -200, opacity: 0 },
  exit: { opacity: 0, scale: 0.5, transition: { duration: 0.3 } },
};
export default function QuestionCard({
  section,
  selectedQuestion,
  selectedSection,
  setSelectedQuestion,
  setSelectedSection,
  answers,
  setAnswers,
  id,
  name,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionClick = async (optionIndex, item) => {
    if (item.answers_no === 1) {
      if (selectedOption === optionIndex) {
        setSelectedOption(null); // Deselect if the same div is clicked again
      } else {
        setSelectedOption(optionIndex);
      }
      setSelectedAnswers((prev) =>
        prev.includes(optionIndex)
          ? prev.filter((id) => id !== optionIndex)
          : [...prev, optionIndex]
      );

      setAnswers({ ...answers, [item.question_no]: [optionIndex] });
      if (!isLoading) {
        setIsLoading(true);
        await axios
          .post("/quizUpdate", {
            id: id,
            name: name,
            question_no: item.question_no,
            answers: [optionIndex],
          })
          .then(async (res) => {
            if (res.status === 200) {
              setSelectedOption(null);
              setSelectedAnswers([]);
              if (item.question_no === 3) {
                setSelectedSection(2);
              } else if (item.question_no === 8) {
                setSelectedSection(3);
              } else if (item.question_no === 14) {
                setSelectedSection(4);
              } else if (item.question_no >= 20) {
                setSelectedSection(5);
              }
              // await new Promise((r) => setTimeout(r, 500));
              setSelectedQuestion((prevValue) => prevValue + 1);
            }
          })
          // console.log("Data sent successfully");
          .catch((error) => {
            console.error("Error sending data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      //
    } else if (selectedAnswers.length < item.answers_no) {
      setSelectedAnswers((prev) =>
        prev.includes(optionIndex)
          ? prev.filter((id) => id !== optionIndex)
          : [...prev, optionIndex]
      );
    } else if (selectedAnswers.length === item.answers_no) {
      if (selectedAnswers.includes(optionIndex)) {
        setSelectedAnswers(
          (prev) =>
            prev.includes(optionIndex) &&
            prev.filter((id) => id !== optionIndex)
        );
      }
    }
  };
  const handleNext = async (item) => {
    if (
      selectedAnswers.length <= item.answers_no &&
      selectedAnswers.length >= 1
    ) {
      setAnswers({ ...answers, [item.question_no]: selectedAnswers });
      if (!isLoading) {
        setIsLoading(true);

        await axios
          .post("/quizUpdate", {
            id: id,
            name: name,
            question_no: item.question_no,
            answers: selectedAnswers,
          })
          .then(async (res) => {
            if ((res.status = 200)) {
              setSelectedAnswers([]);
              setSelectedOption(null);
              if (item.question_no === 3) {
                setSelectedSection(2);
              } else if (item.question_no === 8) {
                setSelectedSection(3);
              } else if (item.question_no === 14) {
                setSelectedSection(4);
              } else if (item.question_no >= 20) {
                setSelectedSection(5);
              }
              setSelectedQuestion((prevValue) => prevValue + 1);
            }
          })
          .catch((error) => {
            console.error("Error sending data:", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };
  const handleBack = async (item) => {
    setSelectedQuestion((prevValue) => prevValue - 1);

    if (item.question_no === 4) {
      setSelectedSection(1);
    } else if (item.question_no === 9) {
      setSelectedSection(2);
    } else if (item.question_no === 15) {
      setSelectedSection(3);
    }
  };

  useEffect(() => {
    if (answers[selectedQuestion]) {
      setSelectedAnswers(answers[selectedQuestion]);
    }
  }, [selectedQuestion]);

  return (
    <div className='question-main'>
      {section.map((item, index) =>
        selectedSection !== item.section ? (
          <div key={`${index}+${item.question_no}`} className='question-div'>
            {" "}
          </div>
        ) : (
          selectedQuestion === item.question_no && (
            <div key={index}>
              <div className='question-div'>
                {/* <Divider variant='middle' component='div' /> */}
                <div className='d-flex'>
                  <p>Q: &nbsp; </p>
                  <p>{item.question}</p>
                </div>
              </div>
              {item.answers_no > 1 && (
                <div className='options-hint'>
                  Select upto {item.answers_no} options
                </div>
              )}
              {Object.entries(item.options).map(
                ([optionKey, optionValue], optionIndex) => (
                  <div key={optionIndex} className='options'>
                    <motion.div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      id='button'
                      initial={
                        selectedOption === null
                          ? optionIndex % 2 === 0
                            ? "hiddenRight"
                            : "hiddenLeft"
                          : "visible"
                      }
                      animate={
                        selectedOption === null ||
                        selectedOption === optionIndex
                          ? "visible"
                          : "exit"
                      }
                      exit='exit'
                      variants={variants}
                      transition={{ duration: 0.5 }}
                      onClick={() => handleOptionClick(optionIndex, item)}
                    >
                      <div
                        className='options-div'
                        style={{
                          boxShadow:
                            selectedAnswers.length > 0 &&
                            selectedAnswers.includes(optionIndex)
                              ? "0px 10px 30px -5px rgba(45, 129, 132, 0.6)"
                              : "none",

                          transform:
                            selectedAnswers.length > 0 &&
                            selectedAnswers.includes(optionIndex)
                              ? "scale(1.03)"
                              : "",
                        }}
                      >
                        <div className='options-bullet'>
                          <h6>{optionKey}</h6>
                        </div>
                        <h6
                          style={{
                            color:
                              selectedAnswers.length > 0 &&
                              selectedAnswers.includes(optionIndex)
                                ? "#4bc9ce"
                                : "",
                          }}
                        >
                          {optionValue}
                        </h6>
                      </div>
                    </motion.div>
                  </div>
                )
              )}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {item.question_no === 1 ? (
                  ""
                ) : (
                  <div
                    className='back-arrow'
                    onClick={() => {
                      handleBack(item);
                    }}
                  >
                    <Icon icon='carbon:arrow-left' />{" "}
                  </div>
                )}
                {item.answers_no > 1 && (
                  <div className='quiz-button' onClick={() => handleNext(item)}>
                    <h5>Next</h5>
                  </div>
                )}
              </div>
              {/* <div
                className='quiz-button'
                onClick={() =>
                  console.log(selectedAnswers.length, item.answers_no)
                }
              >
                <h5>log</h5>
              </div> */}
            </div>
          )
        )
      )}
    </div>
  );
}
