import ScoreCard from "./ScoreCard";
import { useState, useEffect } from "react";

export default function QuizResult({ score, name }) {
  const [finalScore, setFinalScore] = useState([]);
  useEffect(() => {
    let scores = [score.ib, score.er, score.mc, score.cm, score.wm];
    const highestScore = Math.max(...scores);
    const percentages = scores.map((score) =>
      Math.round((score / highestScore) * 100)
    );
    // let scorePercent = {
    //   ib: percentages[0],
    //   er: percentages[1],
    //   mc: percentages[2],
    //   cm: percentages[3],
    //   wm: percentages[4],
    // };
    // const dataArray = Object.entries(scorePercent);
    // dataArray.sort((a, b) => b[1] - a[1]);
    let scoreObj = {
      ib: {
        title: "Investment Banking",
        score: percentages[0],
        logo: [
          { url: "images/companies_logo/1.png" },
          { url: "images/companies_logo/2.png" },
          { url: "images/companies_logo/3.png" },
          { url: "images/companies_logo/4.png" },
        ],
        ul: [
          { li: "1000+ Invesment Banks in India" },
          {
            li: " Handles over $1 trillion in mergers and acquisitions annually.",
          },
          {
            li: 'Investment bankers are known for their grueling "work hard, play hard" culture.',
          },
          { li: "Average CTC for Investment Bankers in India ₹10,00,000." },
        ],
        link: "/test",
      },
      er: {
        title: "Equity Research",
        score: percentages[1],
        logo: [
          { url: "images/companies_logo/9.png" },
          { url: "images/companies_logo/10.png" },
          { url: "images/companies_logo/11.png" },
          { url: "images/companies_logo/12.png" },
        ],
        ul: [
          { li: "Over 35,000 equity research analysts worldwide." },
          {
            li: `Known as "Wall Street's Detectives" for their investigative approach to analyzing companies.`,
          },
          {
            li: "Analysts are known for their love of financial modeling and spreadsheets.",
          },
        ],
        link: "/test",
      },
      mc: {
        title: "Management Consulting",
        score: percentages[2],
        logo: [
          { url: "images/companies_logo/5.png" },
          { url: "images/companies_logo/6.png" },
          { url: "images/companies_logo/7.png" },
          { url: "images/companies_logo/8.png" },
        ],
        ul: [
          { li: "Consulting firms are the CEO - Factory of the world." },
          {
            li: "Management consulting services account for $300 billion in annual revenue.",
          },
          {
            li: "The Big 4 consulting firms (Deloitte, PwC, EY, KPMG) employ over 1 million consultants worldwide.",
          },
        ],
        link: "/test",
      },
      cm: {
        title: "Credit Management",
        score: percentages[3],
        logo: [
          { url: "images/companies_logo/13.png" },
          { url: "images/companies_logo/14.png" },
          { url: "images/companies_logo/15.png" },
          { url: "images/companies_logo/16.png" },
        ],
        ul: [
          {
            li: "The global credit card industry generates over $180 billion in annual revenue.",
          },
          {
            li: `Professionals often refer to themselves as "Credit Ninjas"`,
          },
          {
            li: "The credit management industry employs over 500,000 professionals.",
          },
        ],
        link: "/test",
      },
      wm: {
        title: "Wealth Management",
        score: percentages[4],
        logo: [
          { url: "images/companies_logo/17.png" },
          { url: "images/companies_logo/18.png" },
          { url: "images/companies_logo/19.png" },
          { url: "images/companies_logo/20.png" },
        ],
        ul: [
          {
            li: `The term "High Net Worth Individual" refers to individuals with assets of over $1Mn.`,
          },
          {
            li: `The top 10 wealth management firms manage over $25 trillion in assets.`,
          },
          {
            li: "India ranked fifth on the list of countries with the most Ultra HNI worldwide.",
          },
        ],
        link: "/test",
      },
    };
    const dataArray = Object.entries(scoreObj);
    dataArray.sort((a, b) => b[1]["score"] - a[1]["score"]);
    setFinalScore(dataArray);
    // scorePercent.map((data) => {
    //   console.log(data[0], data[1]);
    //   if (data[0] === "ib") {
    //     finalScore.ib = data[1];
    //   }
    // });
    // console.log(finalScore, dataArray);
    // console.log(dataArray[0][1]);
    // console.log(score, percentages, scorePercent, dataArray);
  }, [score]);
  return (
    <div className='result-main'>
      <h3>You did it!&nbsp;{name}</h3>
      <h5>
        The assessment is complete and you're ready to embark on your passionate
        career journey.
      </h5>

      <div
        className='scorecard-container'
        // style={{
        //   display: "flex",
        //   flexWrap: "wrap",
        //   alignItems: "center",
        //   gap: "25px",
        // }}
      >
        {finalScore.map((data) => {
          return <ScoreCard key={data[0]} data={data[1]} />;
        })}
      </div>
    </div>
  );
}
