import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { Icon } from "@iconify/react";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);

  const toggleClass = () => {
    setisMenu(!isMenu);
    setResponsiveclose(!isResponsiveclose);
    boxClassSubMenu.push("");
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
    // boxClass.push("move");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  return (
    <header className='header__middle'>
      <div className='nav__container'>
        <div className='row'>
          <div className='header__middle__menus'>
            <nav className='main-nav '>
              {/* Responsive Menu Button */}

              <span
                className='menubar__button'
                style={{
                  display: "none",
                }}
                onClick={toggleClass}
              >
                {isResponsiveclose ? (
                  <Icon icon='line-md:menu-to-close-transition' key={isMenu} />
                ) : (
                  <Icon icon='line-md:close-to-menu-transition' key={isMenu} />
                )}
              </span>

              <ul className={boxClass.join(" ")}>
                <li
                  onClick={toggleSubmenu}
                  className='menu-item sub__menus__arrows'
                >
                  {" "}
                  <Link to='#'>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Explore&nbsp;&nbsp; <Icon icon='grommet-icons:down' />
                    </div>
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      {" "}
                      <NavLink
                        onClick={toggleClass}
                        activeClassName='is-active'
                        to={`/investmentBanking`}
                      >
                        {" "}
                        Investment Banker{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName='is-active'
                        to={`/managementConsulting`}
                      >
                        {" "}
                        Management Consulting{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName='is-active'
                        to={`/equityResearch`}
                      >
                        {" "}
                        Equity Research{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName='is-active'
                        to={`/wealthManagement`}
                      >
                        {" "}
                        Wealth Management{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName='is-active'
                        to={`/creditManagement`}
                      >
                        {" "}
                        Credit Management{" "}
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>

                <li className='menu-item '>
                  <NavLink
                    onClick={toggleClass}
                    activeClassName='is-active'
                    to={`/mentorshop`}
                  >
                    {" "}
                    Mentorship{" "}
                  </NavLink>{" "}
                </li>
                <li className='menu-item '>
                  <NavLink
                    onClick={toggleClass}
                    activeClassName='is-active'
                    to={`/Contact`}
                  >
                    {" "}
                    Contact{" "}
                  </NavLink>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbarmenu;
